const ToggleSwitch = ({ 
    checked, 
    onChange, 
    label, 
    enabledText = "Enabled", 
    disabledText = "Disabled" 
  }) => {
    return (
      <div className="flex items-center space-x-3">
        <label className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              className="sr-only"
              checked={checked}
              onChange={onChange}
            />
            <div className={`
              w-12 h-6 
              ${checked ? 'bg-blue-500' : 'bg-gray-300'}
              rounded-full 
              shadow-inner 
              relative
              transition-colors 
              duration-300
            `}>
              <div className={`
                absolute 
                top-1/2 
                -translate-y-1/2
                w-5 h-5 
                bg-white 
                rounded-full 
                shadow 
                transform 
                transition-transform 
                duration-300
                ${checked ? 'translate-x-6' : 'translate-x-1'}
              `}></div>
            </div>
          </div>
          <span className="ml-3 text-sm text-gray-700">
            {checked ? enabledText : disabledText}
          </span>
        </label>
      </div>
    );
  };
  export default ToggleSwitch