import React from 'react';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Paginate = ({ currentPage, totalItems, pageSize, onPageChange, onPageSizeChange }) => {
  const pageCount = Math.ceil(totalItems / pageSize);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  return (
    <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-2">
      <div className="flex items-center gap-4">
        <div className="text-sm text-muted-foreground">
          Showing {startItem} to {endItem} of {totalItems} results
        </div>
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          className="w-[120px] px-2 py-1 border border-gray-200 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
        >
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
        </select>
      </div>
      
      <ReactPaginate
  pageCount={pageCount}
  pageRangeDisplayed={3}
  marginPagesDisplayed={1}
  forcePage={currentPage - 1}
  onPageChange={(selectedItem) => onPageChange(selectedItem.selected + 1)}
  className="flex items-center gap-1"
  pageClassName="flex"
  pageLinkClassName="px-3 py-2 rounded-md hover:bg-gray-100 text-sm font-medium"
  activeClassName="bg-blue-600 text-white" // More prominent active state
  activeLinkClassName="text-white" // Ensure text is white on active page
  previousClassName="flex"
  nextClassName="flex"
  previousLinkClassName="px-2 py-2 rounded-md hover:bg-gray-100 text-gray-600"
  nextLinkClassName="px-2 py-2 rounded-md hover:bg-gray-100 text-gray-600"
  breakClassName="flex"
  breakLinkClassName="px-3 py-2 text-sm text-gray-500"
  previousLabel={<ChevronLeft className="h-4 w-4" />}
  nextLabel={<ChevronRight className="h-4 w-4" />}
  disabledClassName="opacity-50 cursor-not-allowed"
  renderOnZeroPageCount={null}
/>
    </div>
  );
};

export default Paginate;