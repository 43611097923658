import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import Moment from 'react-moment';
import LoadingSpinner from '../components/LoadingSpinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import RetailAccountDetails from '../components/RetailAccountDetails';

const CreditInfo = () => {

  const location =useLocation()
  const navigate =useNavigate()

  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const userInfo = queryParams.get('userInfo');
  const isUnpaid = queryParams.get('isUnpaid');
  const id = queryParams.get('id');
  const isEquifax = queryParams.get('isEquifax');
  const isCibil = queryParams.get('isCibil');

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      fetchData();
    }
   
  }, [userId, id]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let response;

        response = await axiosInstance.get(`/user/creditLogsByid/${id}`);
      
      setData(response.data.data.apiData.data);
      console.log(response.data.data.apiData.data)
    } catch (error) {
      toast.error(error?.response?.data.message);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
  };

  const formatDate = (dateNumber) => {
    if (!dateNumber) {
      return '-';
    }
    const date = dateNumber.toString();
    if (typeof date !== 'string') return '';
    return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
  };

  if (loading) {
    return (
    <LoadingSpinner />
    );
  }

  if (!data) {
    return (
      <div>
        No data found
      </div>
    );
  }

//   if (isCibil) {
//     return (
//       <Layout title="credit report">
//         <Cibil userId={userInfo} cibilData={data.apiData} />
//       </Layout>
//     );
//   }

  if (isEquifax) {
    return (
      <>
        <RetailAccountDetails data={data} />
      </>
    );
  }

  if (!data?.apiData?.data?.jsonExperianReport) {
    return (
 
    <div>  No data found</div>  

    );
  }
console.log(data)
  const {
    score,
    createdAt,
    apiData: {
        data:{
      jsonExperianReport: {
        Current_Application: {
          Current_Application_Details: {
            Current_Applicant_Details: { First_Name, Last_Name, IncomeTaxPan, Date_Of_Birth_Applicant, MobilePhoneNumber, genderCodeDescription },
          },
        },
        CAIS_Account: {
          CAIS_Summary: {
            Credit_Account: {
              CreditAccountTotal,
              CreditAccountActive,
              CreditAccountDefault,
              CreditAccountClosed,
              CADSuitFiledCurrentBalance,
            },
            Total_Outstanding_Balance: {
              Outstanding_Balance_Secured,
              Outstanding_Balance_Secured_Percentage,
              Outstanding_Balance_UnSecured,
              Outstanding_Balance_UnSecured_Percentage,
              Outstanding_Balance_All,
            },
          },
          CAIS_Account_DETAILS,
        },
      },
    }
    },
    totalSanctionAmount,
    totalBalanceAmount,
    singleHighestCredit,
    totalMonthlyPaymentAmount,
  } = data;

  const notActiveNorClosedCount = CAIS_Account_DETAILS.filter(
    account => account.accountStatusDescription !== 'ACTIVE' && account.accountStatusDescription !== 'CLOSED'
  ).length;

  const handleCreditEnquiryClick = () => {
    navigate({
      pathname: `${location.pathname}/enquiry`,
      search: location.search,
    });
  };

  return (
    <div className="p-4">
    <h2 className="text-2xl font-bold mb-4">Experian Credit Details</h2>
    <div className="bg-white shadow-md rounded-md p-4 mb-4">
      <div className="md:flex gap-10 items-center">
      <ul className="list-disc pl-4">
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Full Name:</span>
            <span className="text-gray-600"> {First_Name + " " + Last_Name}</span>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Phone Number:</span>
            <span className="text-gray-600"> {MobilePhoneNumber}</span>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Pan:</span>
            <span className="text-gray-600"> {IncomeTaxPan}</span>
          </li>
          <li className="mb-2">
<span className="font-semibold text-gray-800">DOB:</span>
<span className="text-gray-600"> {formatDate(Date_Of_Birth_Applicant)}</span>
</li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Gender:</span>
            <span className="text-gray-600"> {genderCodeDescription}</span>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Credit Score:</span>
            <span className="text-gray-600"> {score}</span>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Total Credit Accounts:</span>
            <span className="text-gray-600"> {CAIS_Account_DETAILS.length}</span>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Active Credit Accounts:</span>
            <span className="text-gray-600"> {CreditAccountActive}</span>
          </li>
        </ul>
        <ul className="list-disc pl-4 mt-2">
    
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Closed Credit Accounts:</span>
            <span className="text-gray-600"> {CreditAccountClosed}</span>
          </li>
          <li className="mb-2">
<span className="font-semibold text-gray-800">Credit Fetch Date:</span>
<span className="text-gray-600"> <Moment format="DD MMM YYYY">{createdAt}</Moment></span>
</li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Total Outstanding Balance:</span>
            <span className="text-gray-600"> {formatCurrency(totalBalanceAmount)}</span>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Secured Balance:</span>
            <span className="text-gray-600"> {formatCurrency(Outstanding_Balance_Secured)}</span>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-gray-800">Unsecured Balance:</span>
            <span className="text-gray-600"> {formatCurrency(Outstanding_Balance_UnSecured)}</span>
          </li>
          <li className={`mb-2 ${notActiveNorClosedCount>0? 'bg-red-100' : ''}`}>
            <span className="font-semibold text-gray-800">Defaulted Credit Accounts:</span>
            <span className="text-gray-600"> {notActiveNorClosedCount}</span>
          </li>
        </ul>
        <div>
        <button
          onClick={() => {
              const currentPath = location.pathname; // Get the current path
              const currentQuery = new URLSearchParams(location.search);// Get the current query parameters
              currentQuery.set('newQueryParam', 'value');
              navigate({
                pathname: `/creditLogs/creditEnquiry/${id}`, // Keep the current path and append /enquiry
                search: currentQuery.toString(), // Set the modified query string
              });
            }}   className="flex items-center justify-center w-full px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
             >
             Credit Enquiry
              </button>
        </div>
      </div>
    </div>

    <h3 className="text-xl font-semibold mb-2">Customer Loans</h3>
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              S.NO
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ACCOUNT ID
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              OPEN DATE
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ACCOUNT TYPE
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              LOAN AMOUNT
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              INTEREST RATE
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              CURRENT BALANCE
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
             Last Payment Date
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              STATUS
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Late Payment
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              VIEW
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
        {CAIS_Account_DETAILS.map((account, index) => {
        const lastDaysPastDue = account.CAIS_Account_History
          .slice()
         
          .find((history) => history.Days_Past_Due > 0);
        
        return (
          <tr
            className={`px-6 py-4 whitespace-nowrap ${
              account.accountStatusDescription !== 'ACTIVE' && account.accountStatusDescription !== 'CLOSED'
                ? 'bg-red-500'
                : account.CAIS_Account_History.some((history) => history.Days_Past_Due > 0)
                  ? 'bg-red-100'
                  : account.accountStatusDescription === 'ACTIVE'
                    ? 'bg-yellow-300'
                    : ""
            }`}
            key={account.Account_Number}
          >
            <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
            <td className="px-6 py-4 whitespace-nowrap">{account.Account_Number}</td>
            <td className="px-6 py-4 whitespace-nowrap">{formatDate(account.Open_Date.toString())}</td>
            <td className="px-6 py-4 whitespace-nowrap">{account.accountTypeDescription}</td>
            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(account.Highest_Credit_or_Original_Loan_Amount)}</td>
            <td className="px-6 py-4 whitespace-nowrap">{account.Rate_of_Interest || "-"}</td>
            <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(account.Current_Balance)}</td>
            <td className="px-6 py-4 whitespace-nowrap">{formatDate(account.Date_of_Last_Payment)}</td>

            <td className="px-6 py-4 whitespace-nowrap">{account.accountStatusDescription}</td>
            <td className="px-6 py-4 whitespace-nowrap">
            {lastDaysPastDue && `${lastDaysPastDue.Days_Past_Due} days ${lastDaysPastDue.Month}/${lastDaysPastDue.Year}  (${account.CAIS_Account_History.indexOf(lastDaysPastDue)==0 && account.accountStatusDescription!="CLOSED" ?"Not Paid":"Paid" })`}                </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <Link to={`/creditLogs/creditReport/${userId ? userId : id}?index=${index}&isId=${id ? "true" : "false"}${isUnpaid ? "&isUnpaid=true" : ""}`} className="hover:bg-indigo-400 border-indigo-500 border shadow text-md hover:text-white py-1 px-4 cursor-pointer rounded-lg shadow">
                View
              </Link>
            </td>
          </tr>
        );
      })}
        </tbody>
      </table>
    </div>
  </div>
  );
};

export default CreditInfo;


