import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IoMdHome } from 'react-icons/io';
import { MdOutlineLogout } from 'react-icons/md';
import { useAuth } from '../context/AuthContext';
import Sidebar from './Sidebar';

const Layout = ({ children, title }) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [show, setSidebar] = useState(true);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setMobileSidebar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const AdminSidebarOptions = () => (
    <div className={`fixed top-0 z-40 flex bg-gradient-to-r from-blue-700 to-blue-600 rounded-b-lg p-2 justify-between items-center shadow-md ${
      show ? 'left-64 right-0' : 'left-20 right-0'
    }`}>
      <nav className="hidden md:flex">
        <ol className="flex items-center space-x-2">
          <li className="cursor-pointer text-blue-100">
            <div className="inline-flex items-center hover:text-white">
              <IoMdHome className="mr-1" />
              <p>{title}</p>
            </div>
          </li>
        </ol>
      </nav>
      <div className="flex items-center">
        <img
          className="w-10 h-10 rounded-full cursor-pointer border-2 border-blue-300"
          src={user?.profileImage || '/default-avatar.jpg'}
          alt="admin avatar"
        />
        <MdOutlineLogout
          size={20}
          className="ml-3 cursor-pointer text-blue-100 hover:text-white"
          onClick={handleLogout}
        />
        <FaBars
          size={20}
          className="ml-3 cursor-pointer block lg:hidden text-blue-100 hover:text-white"
          onClick={() => setMobileSidebar(!mobileSidebar)}
        />
      </div>
    </div>
  );

  return (
    <div className="flex min-h-screen ">
      {/* Sidebar */}
      <div
        className={`${
          mobileSidebar ? 'z-50 absolute top-0 left-0' : 'hidden lg:block'
        } transition-all duration-300 bg-white ${
          show ? 'w-64' : 'w-20'
        } fixed top-0 h-full shadow-md`}
      >
        <Sidebar show={show} setSidebar={setSidebar} mobileSidebar={mobileSidebar} />
      </div>

      {/* Main Content */}
      <div
        className={`flex flex-col min-h-screen w-full overflow-hidden   transition-all duration-300 ${
          show ? 'ml-64' : 'ml-20'
        }`}
      >
        <AdminSidebarOptions />
        {/* Scrollable Content */}
        <div className="pt-16 flex-1 p-6 ">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;