import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';
import LoadingSpinner from '../components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import RetailAccountDetails from '../components/RetailAccountDetails';

const Loader = ({ title }) => (
  <div className="flex justify-center items-center h-screen">
    <p>Loading {title}...</p>
  </div>
);

const ExperianDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Use URLSearchParams to get query parameters
  const queryParams = new URLSearchParams(window.location.search);
  const {id} =useParams()
  const isId = queryParams.get('isId');
  const isUnpaid = queryParams.get('isUnpaid');
  const index = queryParams.get('i""ndex');
  const isEquifax=queryParams.get("isEquifax")
  const formatCurrency = (amount) => {
    if (!amount) return "-";
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
  };

  const formatDate = (dateNumber) => {
    if (!dateNumber) {
      return "-";
    }
    const date = dateNumber.toString();
    if (typeof date !== 'string') return '';
    return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let response;
     
console.log(isId)
      if (!isUnpaid && isId) {
        response = isId == 'true'
          ? await axiosInstance.get(`/user/creditLogsByid/${id}`)
          : await axiosInstance.get(`/user/creditLogsByid/${id}`);
        
      } else {
        response = await axiosInstance.get(`/user/creditLogsByid/${id}`);
      }

      const creditData = response?.data?.data?.apiData?.data?.apiData?.data?.jsonExperianReport?.CAIS_Account.CAIS_Account_DETAILS[Number(index)];
      setData(creditData);
    } catch (error) {
      toast.error(error?.response?.data.message || "An error occurred");
      console.error("Error fetching credit info:", error);
    } finally {
      setLoading(false);
    }
  };
console.log("first")
  useEffect(() => {
    if (id) {
      fetchData();
      console.log("first")
    }
  }, [id, isId, isUnpaid, index]);

  if (loading) {
    return <LoadingSpinner title="credit report" />;
  }

  if (!data) {
    return <div>No data found</div>;
  }
  if(isEquifax?.length){
    return    (
      <RetailAccountDetails data={data} />
    )
  }
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 m-4">
      <h2 className="text-2xl font-bold mb-4">Experian Credit Details</h2>
      
      {/* User Info Section */}
      <div className="bg-white shadow-md rounded-md p-4 mb-4">
        <div className="md:flex gap-10 items-center">
          <ul className="list-disc pl-4">
            <li className="mb-2">
              <span className="font-semibold text-gray-800">Account ID:</span>
              <span className="text-gray-600"> {data.Account_Number}</span>
            </li>
            <li className="mb-2">
              <span className="font-semibold text-gray-800">Account Type Description:</span>
              <span className="text-gray-600"> {data.accountTypeDescription}</span>
            </li>
            
            {data.CAIS_Holder_Details && data.CAIS_Holder_Details.length > 0 && (
              <>
                <li className="mb-2">
                  <span className="font-semibold text-gray-800">Account Holder Name:</span>
                  <span className="text-gray-600">
                    {data.CAIS_Holder_Details[0].First_Name_Non_Normalized + " " + 
                     data.CAIS_Holder_Details[0].Surname_Non_Normalized}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="font-semibold text-gray-800">Address:</span>
                  <span className="text-gray-600">
                    {data.CAIS_Holder_Address_Details[0]?.First_Line_Of_Address_non_normalized}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="font-semibold text-gray-800">Pan:</span>
                  <span className="text-gray-600">
                    {data.CAIS_Holder_Details[0]?.Income_TAX_PAN}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="font-semibold text-gray-800">Dob:</span>
                  <span className="text-gray-600">
                    {formatDate(data.CAIS_Holder_Details[0]?.Date_of_birth)}
                  </span>
                </li>
              </>
            )}
          </ul>

          {/* Additional Details Column */}
          <ul className="list-disc pl-4 mt-2">
            <li className="mb-2">
              <span className="font-semibold text-gray-800">Loan Amount:</span>
              <span className="text-gray-600">
                {formatCurrency(data.Highest_Credit_or_Original_Loan_Amount)}
              </span>
            </li>
            <li className="mb-2">
              <span className="font-semibold text-gray-800">Current Balance:</span>
              <span className="text-gray-600">
                {formatCurrency(data.Current_Balance)}
              </span>
            </li>
            <li className="mb-2">
              <span className="font-semibold text-gray-800">Date of Addition:</span>
              <span className="text-gray-600">
                {formatDate(data.DateOfAddition)}
              </span>
            </li>
            <li className="mb-2">
              <span className="font-semibold text-gray-800">Date Reported:</span>
              <span className="text-gray-600">
                {formatDate(data.Date_Reported)}
              </span>
            </li>
            <li className="mb-2">
              <span className="font-semibold text-gray-800">Date Closed:</span>
              <span className="text-gray-600">
                {formatDate(data.Date_Closed)}
              </span>
            </li>
            <li className="mb-2">
              <span className="font-semibold text-gray-800">Payment History Profile:</span>
              <span className="text-gray-600">
                {data.Payment_History_Profile}
              </span>
            </li>
          </ul>
        </div>
      </div>

      {/* Telephone Details Section */}
      <div className='bg-white shadow-md rounded-md p-4 mb-4'>
        <h3 className="text-xl font-semibold mb-2">Telephone Details</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {['S.NO', 'Email ID', 'Fax Number', 'Telephone Type', 'Telephone Number', 'Mobile Number']
                  .map((header, idx) => (
                    <th key={idx} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {header}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.CAIS_Holder_Phone_Details?.map((telephone, index) => (
                <tr key={`${telephone.EMailId}-${index}`}>
                  <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{telephone.EMailId}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{telephone.FaxNumber || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{telephone.Telephone_Type}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{telephone.Telephone_Number || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{telephone.Mobile_Telephone_Number || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Address Details Section */}
      <div className='bg-white shadow-md rounded-md p-4 mb-4'>
        <h3 className="text-xl font-semibold mb-2">Address Details</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {['S.NO', 'First Line of Address', 'Second Line of Address', 'City', 'State', 'Country Code', 'ZIP Code']
                  .map((header, idx) => (
                    <th key={idx} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {header}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.CAIS_Holder_Address_Details.map((address, index) => (
                <tr key={`${address.First_Line_Of_Address_non_normalized}-${index}`}>
                  <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{address.First_Line_Of_Address_non_normalized}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{address.Second_Line_Of_Address_non_normalized || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{address.City_non_normalized || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{address.State_non_normalized}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{address.CountryCode_non_normalized}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{address.ZIP_Postal_Code_non_normalized}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* CAIS Account Details Section */}
      <div className='bg-white shadow-md rounded-md p-4 mb-4'>
        <h3 className="text-xl font-semibold mb-2">CAIS Account Details</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {['S.NO', 'YEAR', 'MONTH', 'DAYS PAST DUE']
                  .map((header, idx) => (
                    <th key={idx} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {header}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.CAIS_Account_History?.map((account, index) => (
                <tr 
                  key={`${account.Year}-${account.Month}`} 
                  className={`px-6 py-4 whitespace-nowrap ${account.Days_Past_Due > 0 ? 'bg-red-100' : ''}`}
                >
                  <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{account.Year}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{account.Month}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{account.Days_Past_Due}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExperianDetails;