
import React, { useState } from "react";
import { X } from "lucide-react";
import Moment from "react-moment";

const ApiLogsTable = ({ logs,loading }) => {
  const handleSort = (key) => {
    console.log(`Sorting by ${key}`);
    // Implement sorting logic
  };

  const handleStatusToggle = (key, isActive) => {
    console.log(`Toggling status for ${key}: ${isActive}`);
    // Implement toggle logic
  };

  const navigate = (url) => {
    console.log(`Navigating to ${url}`);
    // Implement navigation logic
  };

  return (
    <table className="w-full">
        
      <thead className="bg-gray-50">
        <tr>
          <th onClick={() => handleSort("key")} className="px-6 py-3 text-left cursor-pointer">
            Key
          </th>
          <th className="px-6 py-3 text-left">Module</th>
          <th className="px-6 py-3 text-left">Details</th>
          <th className="px-6 py-3 text-left">Status</th>
          <th className="px-6 py-3 text-left">Created At</th>
      <th className="px-6 py-3 text-left">User ID</th>
      <th className="px-6 py-3 text-left">User</th>
      <th className="px-6 py-3 text-left">Comment</th>
      <th className="px-6 py-3 text-left">HTTP Method</th>

         
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200"> {loading ? (   <tr>
              <td colSpan={6} className="px-6 py-10 text-center">
                <div className="flex justify-center">
                  <div className="w-6 h-6 border-2 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
                </div>
              </td>
            </tr>
          ) : logs?.length === 0 ? (
            <tr>
              <td colSpan={6} className="px-6 py-10 text-center text-gray-500">
                No loans found
              </td>
            </tr>
          ) : (
        logs.map((log) => (
          <tr key={log.id}>
            <td className="px-6 py-4">{log.id}</td>
            <td className="px-6 py-4">{log.serviceName}</td>
            <td className="px-6 py-4">
              <DetailsModal
                log={log}
                trigger={
                  <button className="px-4 py-2 border border-blue-600 rounded hover:bg-blue-50">
                    Details
                  </button>
                }
              />
            </td>
            <td className="px-6 py-4">
              <span
                className={`px-2 py-1 rounded-full text-sm ${
                  log.isSuccessful ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                }`}
              >
                {log.isSuccessful ? "Successful" : "Failed"}
              </span>
            </td>
            <td className="px-6 py-4">  <Moment  format="DD MMM YYYY hh:mm:ss A">
        {log.createdAt}
      </Moment></td>
        <td className="px-6 py-4">{log.userId}</td>
        <td className="px-6 py-4">{log.user}</td>
        <td className="px-6 py-4">{log.comment || "N/A"}</td>
        <td className="px-6 py-4">{log.httpMethod}</td>
         
          </tr>
        ))
    )}
      </tbody>
    </table>
  );
};

const DetailsModal = ({ log, trigger }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
  
    return (
      <>
        <div onClick={handleOpen}>{trigger}</div>
        {isOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-50">
            <div className="relative w-full max-w-4xl mx-auto my-6">
              <div className="flex flex-col w-full bg-white rounded-lg shadow-lg">
                {/* Modal Header */}
                <div className="flex items-center justify-between p-5 border-b border-gray-200 rounded-t">
                  <h3 className="text-lg font-semibold text-gray-800">API Log Details</h3>
                  <button
                    className="text-gray-400 hover:text-gray-600"
                    onClick={handleClose}
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
  
                {/* Modal Content (Scrollable) */}
                <div className="p-6 overflow-y-auto max-h-[60vh]">
                  {Object.entries(log).map(([key, value]) => (
                    <div key={key} className="mb-4">
                      <div className="text-sm font-semibold text-gray-700">{key}:</div>
                      <pre className="mt-1 text-sm text-gray-600 bg-gray-50 border border-gray-200 rounded p-2 overflow-auto">
                        {typeof value === "object" ? JSON.stringify(value, null, 2) : value}
                      </pre>
                    </div>
                  ))}
                </div>
  
                {/* Modal Footer */}
                <div className="flex items-center justify-end p-4 border-t border-gray-200 rounded-b">
                  <button
                    className="px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded hover:bg-gray-700"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  

export default ApiLogsTable;
