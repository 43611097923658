import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { showErrorToast } from '../../utils/toast';
import { formatDate } from '../../utils/helper';

const initialState = {
  apiLogs: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10,
    hasNextPage: false,
    hasPrevPage: false
  },
  loading: false,
  error: null,
  filters: {
    search: '',
    searchType: '', // Additional filter for API logs
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    startDate: null,
    endDate: null,
  },
};

export const fetchApiLogs = createAsyncThunk(
  'apiLogs/fetchApiLogs',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { filters } = getState().apiLogs;
      const queryParams = new URLSearchParams({
        page: filters.page,
        limit: filters.limit,
        search: filters.search,
        searchType: filters.searchType,
      });

      if (filters.startDate && filters.endDate) {
        queryParams.set('startDate', formatDate(filters.startDate));
        queryParams.set('endDate', formatDate(filters.endDate));
      }

      const response = await axiosInstance.get(`/api-logs/list?${queryParams}`);
      return response.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to fetch API logs';
      showErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const apiLogsSlice = createSlice({
  name: 'apiLogs',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiLogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchApiLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.apiLogs = action.payload.apiLogs;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchApiLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilter, resetFilters } = apiLogsSlice.actions;
export default apiLogsSlice.reducer;