import React from 'react';
import Moment from 'react-moment';

const RetailAccountDetails = (props ) => {
    console.log(props.data)
   const accounts=props?.data.apiData?.data?.equifaxReport?.RetailAccountDetails || props?.data?.apiData?.data?.cCRResponse?.cIRReportDataLst?.[0]?.cIRReportData?.retailAccountDetails
const userInfo=props?.data?.apiData.data?.equifaxReport?.IDAndContactInfo.PersonalInfo?.Name || props?.data?.apiData?.data?.cCRResponse?.cIRReportDataLst?.[0]?.cIRReportData?.iDAndContactInfo?.personalInfo?.name
const accountSummary=props?.data.apiData?.data?.equifaxReport?.RetailAccountsSummary || props?.data?.apiData?.data?.cCRResponse?.cIRReportDataLst?.[0]?.cIRReportData?.retailAccountsSummary
console.log(props?.data.apiData?.data?.equifaxReport?.RetailAccountsSummary,"dfwDSFDS")
if(!userInfo){
    
        return <div>No data found</div>;
      
}
   const formatCurrency = (amount) => {
    if(!amount)
      return 
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
  };
  return (
    <div>
         <div className="bg-white shadow-md rounded-md p-4 mb-4">
      <div className="grid md:grid-cols-2 gap-6">
        <div>
          <h2 className="text-xl font-semibold mb-3">User Information</h2>
          <ul className="space-y-2">
            <li><span className="font-semibold">Full Name:</span> {userInfo?.FullName}</li>
            <li><span className="font-semibold">Credit Score:</span> {props.data.score}</li>
            <li><span className="font-semibold">Total Credit Accounts:</span> {accountSummary?.NoOfAccounts}</li>
            <li><span className="font-semibold">Active Credit Accounts:</span> {accountSummary?.NoOfActiveAccounts}</li>
            <li><span className="font-semibold">Closed Credit Accounts:</span> {accountSummary?.NoOfAccounts ||0 - accountSummary?.noOfActiveAccounts ||0}</li>
            <li >
  <span className="font-semibold">Credit Fetch Date:</span>
<Moment  format="DD MMM YYYY">{accountSummary?.createdAt}</Moment>
  </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-3">Account Summary</h2>
          <ul className="space-y-2">
            <li><span className="font-semibold">Average Open Balance:</span> {formatCurrency(accountSummary?.AverageOpenBalance)}</li>
            <li><span className="font-semibold">Most Severe Status (24 Months):</span> {accountSummary?.MostSevereStatusWithIn24Months}</li>
            <li><span className="font-semibold">Past Due Accounts:</span> {accountSummary?.NoOfPastDueAccounts}</li>
            <li><span className="font-semibold">Write-Offs:</span> {accountSummary?.NoOfWriteOffs}</li>
            <li><span className="font-semibold">Zero Balance Accounts:</span> {accountSummary?.NoOfZeroBalanceAccounts}</li>
            <li><span className="font-semibold">Oldest Account:</span> {accountSummary?.OldestAccount}</li>
            <li><span className="font-semibold">Most Recent Account:</span> {accountSummary?.RecentAccount}</li>
          </ul>
        </div>
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-3">Financial Details</h2>
        <div className="grid md:grid-cols-2 gap-6">
          <ul className="space-y-2">
            <li><span className="font-semibold">Highest Balance:</span> {formatCurrency(accountSummary?.SingleHighestBalance)}</li>
            <li><span className="font-semibold">Highest Credit:</span> {formatCurrency(accountSummary?.SingleHighestCredit)}</li>
            <li><span className="font-semibold">Highest Sanction Amount:</span> {formatCurrency(accountSummary?.SingleHighestSanctionAmount)}</li>
            <li><span className="font-semibold">Total Balance Amount:</span> {formatCurrency(accountSummary?.TotalBalanceAmount)}</li>
          </ul>
          <ul className="space-y-2">
            <li><span className="font-semibold">Total Credit Limit:</span> {formatCurrency(accountSummary?.TotalCreditLimit)}</li>
            <li><span className="font-semibold">Total High Credit:</span> {formatCurrency(accountSummary?.TotalHighCredit)}</li>
            <li><span className="font-semibold">Total Monthly Payment:</span> {formatCurrency(accountSummary?.TotalMonthlyPaymentAmount)}</li>
            <li><span className="font-semibold">Total Past Due:</span> {formatCurrency(accountSummary?.TotalPastDue)}</li>
            <li><span className="font-semibold">Total Sanction Amount:</span> {formatCurrency(accountSummary?.TotalSanctionAmount)}</li>
          </ul>
        </div>
      </div>
    </div>
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Seq</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Source</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Account Type</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Institution</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Account Number</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Account Status</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ownership Type</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {accounts?.map((account) => (
            <tr key={account.seq}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{account.Seq}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{account.Source}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{account.AccountType}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{account.Institution}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{account.AccountNumber}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{account.AccountStatus}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{account.OwnershipType}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default RetailAccountDetails;



