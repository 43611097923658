import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../components/LoadingSpinner";
import axiosInstance from "../utils/axiosInstance";
import ToggleSwitch from "../components/ToggleSwitch";

// Reusable Toggle Switch Component


// Reusable Input Field Component
const InputField = ({ 
  label, 
  name, 
  type = "text", 
  value, 
  onChange, 
  error, 
  disabled = false, 
  placeholder,
  className = "" 
}) => {
  return (
    <div className="pb-4">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
        {error && <span className="text-red-500 ml-1">*</span>}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        className={`
          w-full 
          px-3 
          py-2 
          border 
          ${error ? 'border-red-500' : 'border-gray-300'} 
          rounded-md 
          shadow-sm 
          focus:outline-none 
          focus:ring-2 
          ${error ? 'focus:ring-red-500' : 'focus:ring-blue-500'}
          focus:border-transparent
          transition-all
          duration-200
          ${disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}
          ${className}
        `}
      />
      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}
    </div>
  );
};

// Object Field Editor Component
const ObjectFieldEditor = ({ 
  label, 
  value, 
  onChange, 
  sampleObject, 
  error 
}) => {
  const [localValue, setLocalValue] = useState(value || []);

  // Handle adding a new object
  const addObject = () => {
    setLocalValue([...localValue, { ...sampleObject }]);
    onChange([...localValue, { ...sampleObject }]);
  };

  // Handle removing an object
  const removeObject = (index) => {
    const newValue = localValue.filter((_, i) => i !== index);
    setLocalValue(newValue);
    onChange(newValue);
  };

  // Handle changing a specific field in an object
  const handleObjectChange = (index, field, newValue) => {
    const updatedValue = [...localValue];
    updatedValue[index] = {
      ...updatedValue[index],
      [field]: newValue
    };
    setLocalValue(updatedValue);
    onChange(updatedValue);
  };

  // Render input based on field type
  const renderFieldInput = (object, objectIndex, fieldName, fieldValue) => {
    // Determine field type based on value type
    if (typeof fieldValue === 'boolean') {
      return (
        <ToggleSwitch
          checked={object[fieldName] === true}
          onChange={() => handleObjectChange(
            objectIndex, 
            fieldName, 
            !object[fieldName]
          )}
          enabledText="True"
          disabledText="False"
        />
      );
    }

    if (typeof fieldValue === 'number') {
      return (
        <InputField
          type="number"
          name={`${fieldName}-${objectIndex}`}
          value={object[fieldName]}
          onChange={(e) => handleObjectChange(
            objectIndex, 
            fieldName, 
            Number(e.target.value)
          )}
        />
      );
    }

    // Default to text input
    return (
      <InputField
        name={`${fieldName}-${objectIndex}`}
        value={object[fieldName]}
        onChange={(e) => handleObjectChange(
          objectIndex, 
          fieldName, 
          e.target.value
        )}
      />
    );
  };
  function camelCaseToLabel(fieldName) {
    const label = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2');
    
    return label.charAt(0).toUpperCase() + label.slice(1);
  }
  

  return (
    <div className="pb-4">
      <div className="flex justify-between items-center mb-2">
        <label className="block text-sm font-medium text-gray-700">
          {label}
          {error && <span className="text-red-500 ml-1">*</span>}
        </label>
        <button 
          type="button"
          onClick={addObject}
          className="
            px-3 
            py-1 
            bg-green-500 
            text-white 
            rounded-md 
            hover:bg-green-600 
            text-sm
          "
        >
          + Add Item
        </button>
      </div>

      <div className="grid md:grid-cols-2 gap-4">
        {localValue.map((object, objectIndex) => (
          <div 
            key={objectIndex} 
            className="
              border 
              rounded-md 
              p-4 
              bg-gray-50 
              relative
            "
          >
            <button
              type="button"
              onClick={() => removeObject(objectIndex)}
              className="
                absolute 
                top-2 
                right-2 
                text-red-500 
                hover:text-red-700
                text-sm
              "
            >
              Remove
            </button>
            {Object.entries(object).map(([fieldName, fieldValue]) => (
              <div key={fieldName} className="mb-2">
                <label className="block text-xs text-gray-600 mb-1">
                  {camelCaseToLabel(fieldName)}
                </label>
                {renderFieldInput(object, objectIndex, fieldName, fieldValue)}
              </div>
            ))}
          </div>
        ))}
      </div>

      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}
    </div>
  );
};

const EditfeatureConfig = () => {
  const { configId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [sampleObject,setSampleObject]=useState({})
  const [formData, setFormData] = useState({
    key: "",
    value: [],
    description: "",
    isActive: false,
    moduleName: ""
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (configId) {
      fetchConfig();
    }
  }, [configId]);

  const fetchConfig = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/feature-configs/getByKey/${configId}`);
      const configDetails = response.data.data;

      if (configDetails) {
        setFormData({
          key: configDetails.key,
          value: configDetails.value || [],
          description: configDetails.description,
          isActive: configDetails.isActive,
          moduleName: configDetails.moduleName
        });
      }
      setSampleObject(response.data.data.sample)
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error fetching configuration");
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate key
    if (!formData.key) {
      newErrors.key = "Configuration key is required";
    }

    // Validate description
    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    // Validate value array
    if (!formData.value || formData.value.length === 0) {
      newErrors.value = "At least one configuration item is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const payload = {
          ...formData,
        };
        delete payload.key
        delete payload.isActive
        const response = await axiosInstance.put(`/feature-configs/update/${configId}`, payload);
        
        toast.success("Configuration updated successfully!");
        navigate(-1);
      } catch (error) {
        toast.error(error?.response?.data?.message || "Error updating configuration");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleValueChange = (newValue) => {
    setFormData(prevState => ({
      ...prevState,
      value: newValue
    }));
  };

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Edit Feature Configuration</h1>
      </div>
      
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid md:grid-cols-2 gap-6">
            <InputField
              label="Configuration Key"
              name="key"
              value={formData.key}
              disabled={true}
              error={errors.key}
              placeholder="Configuration Key"
            />

            <InputField
              label="Module Name"
              name="moduleName"
              value={formData.moduleName}
              disabled={true}
              placeholder="Module Name"
            />
            <InputField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              error={errors.description}
              placeholder="Enter Configuration Description"
            />
            </div>

            <ObjectFieldEditor
              label="Configuration Values"
              value={formData.value}
              onChange={handleValueChange}
              sampleObject={sampleObject}
              error={errors.value}
            />
         

          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="
                px-6 
                py-2 
                bg-blue-500 
                text-white 
                rounded-md 
                hover:bg-blue-600 
                focus:outline-none 
                focus:ring-2 
                focus:ring-blue-500 
                focus:ring-opacity-50 
                transition-colors 
                duration-300
              "
            >
              Update Configuration
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditfeatureConfig;