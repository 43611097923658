import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { showErrorToast } from '../../utils/toast';

const initialState = {
  settings: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10,
    hasNextPage: false,
    hasPrevPage: false,
  },
  loading: false,
  error: null,
  filters: {
    search: '',
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    sortOrder: 'desc',
  },
};

// Fetch settings data from the server
export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { filters } = getState().settings;
      const queryParams = new URLSearchParams({
        page: filters.page.toString(),
        limit: filters.limit.toString(),
        search: filters.search,
       
      });

      const response = await axiosInstance.get(`/config/settings?${queryParams}`);
      return response.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to fetch settings';
      showErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
      if (action.payload.search) {
        state.filters.page = 1;
      }
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload.settings || [];
        state.pagination ={
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalItems: action.payload.totalItems,
            itemsPerPage:action.payload.pageSize,
            hasNextPage: action.payload.totalPages!=action.payload.currentPage,
            hasPrevPage: action.payload.currentPage!=1,

        };
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilter, resetFilters } = settingsSlice.actions;
export default settingsSlice.reducer;
