import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slices/usersSlice';
import loansReducer from './slices/loansSlice';
import loanApplicationsReducer from './slices/loanApplicationSlice';
import settingsReducer from './slices/settingsSlice';
import rolesReducer from './slices/roleSlice';
import adminReducer from './slices/adminSlice';
import featureConfigsReducer from './slices/featureConfigsSlice';
import apiLogsReducer from './slices/apiLogsSlice';



 const store = configureStore({
  reducer: {
    users: usersReducer,
    loans: loansReducer,
    loanApplications: loanApplicationsReducer,
    settings: settingsReducer,
    roles:rolesReducer,
    admin:adminReducer,
    featureConfigs:featureConfigsReducer,
    apiLogs:apiLogsReducer
  },
});

export default store
