import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import Moment from 'react-moment';
import axiosInstance from '../utils/axiosInstance';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';

const CreditEnquiry = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const {id}=useParams()
  useEffect(() => {
    fetchData();
  
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/user/creditLogsByid/${id}`);
      console.log(response.data.data);
      setData(response.data.data.apiData.data);
    } catch (error) {
      toast.error(error?.response?.data.message);
      console.error("Error fetching file URL:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchExperianData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/user/creditLogsByid/${id}`);
      setData(response.data.data.apiData.data);
    } catch (error) {
      toast.error(error?.response?.data.message);
      console.error("Error fetching file URL:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
  };

  const formatDate = (date) => {
    if (typeof date !== 'string') return '';
    return date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8);
  };

  if (loading) {
    return <LoadingSpinner title='credit report' />;
  }
  if (!data) {
    return <div>No data found</div>;
  }
  
  if (!data?.apiData?.data?.jsonExperianReport) {
    return (
 
    <div>  No data found</div>  

    );
  }
console.log(data)
const {
    score,
    createdAt,
    apiData: {
        data:{
            jsonExperianReport: {
                Current_Application: {
                  Current_Application_Details: {
                    Current_Applicant_Details: { First_Name, Last_Name,IncomeTaxPan,MobilePhoneNumber,genderCodeDescription },
                  },
                },
                CAIS_Account: {
                  CAIS_Summary: {
                    Credit_Account: {
                      CreditAccountTotal,
                      CreditAccountActive,
                      CreditAccountDefault,
                      CreditAccountClosed,
                      CADSuitFiledCurrentBalance,
                    },
                    Total_Outstanding_Balance: {
                      Outstanding_Balance_Secured,
                      Outstanding_Balance_Secured_Percentage,
                      Outstanding_Balance_UnSecured,
                      Outstanding_Balance_UnSecured_Percentage,
                      Outstanding_Balance_All,
                    },
                  },
                  CAIS_Account_DETAILS,
                },
                CAPS:{
                CAPS_Application_Details,
                }
              },
    }
    },
    totalSanctionAmount,
    totalBalanceAmount,
    singleHighestCredit,
    totalMonthlyPaymentAmount,
  } = data;

  const notActiveNorClosedCount = CAIS_Account_DETAILS.filter(
    account => account.accountStatusDescription !== 'ACTIVE' && account.accountStatusDescription !== 'CLOSED'
  ).length;

  return (
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Experian Credit Details</h2>
        <div className="bg-white shadow-md rounded-md p-4 mb-4">
          <div className="md:flex gap-10 items-center">
            <ul className="list-disc pl-4">
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Full Name:</span>
                <span className="text-gray-600"> {First_Name + " " + Last_Name}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Phone Number:</span>
                <span className="text-gray-600"> {MobilePhoneNumber}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Pan:</span>
                <span className="text-gray-600"> {IncomeTaxPan}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Gender:</span>
                <span className="text-gray-600"> {genderCodeDescription}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Credit Score:</span>
                <span className="text-gray-600"> {score}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Total Credit Accounts:</span>
                <span className="text-gray-600"> {CAIS_Account_DETAILS.length}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Active Credit Accounts:</span>
                <span className="text-gray-600"> {CreditAccountActive}</span>
              </li>
            </ul>
            <ul className="list-disc pl-4 mt-2">
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Closed Credit Accounts:</span>
                <span className="text-gray-600"> {CreditAccountClosed}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Credit Fetch Date:</span>
                <span className="text-gray-600"> <Moment  format="DD MMM YYYY">{createdAt}</Moment></span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Total Outstanding Balance:</span>
                <span className="text-gray-600"> {formatCurrency(totalBalanceAmount)}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Secured Balance:</span>
                <span className="text-gray-600"> {formatCurrency(Outstanding_Balance_Secured)}</span>
              </li>
              <li className="mb-2">
                <span className="font-semibold text-gray-800">Unsecured Balance:</span>
                <span className="text-gray-600"> {formatCurrency(Outstanding_Balance_UnSecured)}</span>
              </li>
              <li className={`mb-2 ${notActiveNorClosedCount > 0 ? 'bg-red-100' : ''}`}>
                <span className="font-semibold text-gray-800">Defaulted Credit Accounts:</span>
                <span className="text-gray-600"> {notActiveNorClosedCount}</span>
              </li>
            </ul>
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-2">Customer Enquiries</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  S.NO
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  REPORT NUMBER
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ENQUIRY REASON
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  AMOUNT FINANCED
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  DATE OF REQUEST
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  FINANCE PURPOSE
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  SUBSCRIBER NAME
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  DURATION OF AGREEMENT
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {CAPS_Application_Details?.map((item, index) => (
                <tr className="px-6 py-4 whitespace-nowrap" key={item.ReportNumber}>
                  <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.ReportNumber}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.enquiryReasonDescription}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(item.Amount_Financed)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Moment  format="DD MMM YYYY">{item.dateOfRequest}</Moment>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.financePurposeDescription}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.Subscriber_Name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.Duration_Of_Agreement} years</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

  );
};

export default CreditEnquiry;
