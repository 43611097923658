import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import LoadingSpinner from '../components/LoadingSpinner';
import Layout from '../components/Layout';
import CreditLogsTable from '../Table/CreditLogsTable';

const CreditLogs = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10
  });

  useEffect(() => {
    if (userId) {
      fetchExperianData();
    }
  }, [userId]);

  const fetchExperianData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/user/credit-logs/${userId}`);
      setData(response.data.data);
    } catch (error) {
      toast.error(error?.response?.data.message || 'An error occurred');
      console.error("Error fetching credit logs:", error);
    } finally {
      setLoading(false);
    }
  };

  // Pagination handler (optional, can be expanded)
  const handlePageChange = (newPage) => {
    setFilters(prev => ({ ...prev, page: newPage }));
  };

  return (
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Credit Logs</h2>
        
        <CreditLogsTable
          data={data} 
          loading={loading} 
          filters={filters} 
          userId={userId}
          onPageChange={handlePageChange}
        />
      </div>
  );
};

export default CreditLogs;