import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar, X } from 'lucide-react';

const AdvancedDateRangePicker = ({ startDate, endDate, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    onChange({ 
      startDate: start ? start.toISOString().split('T')[0] : null, 
      endDate: end ? end.toISOString().split('T')[0] : null 
    });
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const handleClear = () => {
    onChange({ startDate: null, endDate: null });
  };

  return (
    <div className="relative w-full">
      <div 
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-4 py-2 text-sm bg-white border border-gray-300 rounded-lg cursor-pointer"
      >
        <div className="flex items-center gap-2">
          <Calendar className="w-4 h-4 text-gray-500" />
          <span>
            {startDate && endDate 
              ? `${formatDate(startDate)} - ${formatDate(endDate)}` 
              : "Select date range"}
          </span>
        </div>
        {(startDate || endDate) && (
          <button onClick={handleClear} className="ml-2">
            <X className="w-4 h-4 text-gray-400" />
          </button>
        )}
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-1 bg-white border rounded-lg shadow-lg">
          <DatePicker
            selected={startDate ? new Date(startDate) : null}
            startDate={startDate ? new Date(startDate) : null}
            endDate={endDate ? new Date(endDate) : null}
            onChange={handleDateChange}
            selectsRange
            inline
            monthsShown={1}
            dateFormat="MMM d, yyyy"
            onClickOutside={() => setIsOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default AdvancedDateRangePicker;