import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

export const fetchAdmins = createAsyncThunk('admin/fetchAdmins', async (filters) => {
  const response = await axiosInstance.get('/admin/get', { params: filters });
  return response.data.data;
});

export const addAdmin = createAsyncThunk('admin/addAdmin', async (adminData) => {
  const response = await axiosInstance.post('/admin/create', adminData);
  return response.data;
});

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    admins: [],
    pagination: { totalItems: 0, currentPage: 1, pageSize: 10 },
    filters: { page: 1, limit: 10, search: '' },
    loading: false,
  },
  reducers: {
    setFilter(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.admins = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchAdmins.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addAdmin.fulfilled, (state) => {
        // Admin added successfully
      });
  },
});

export const { setFilter } = adminSlice.actions;
export default adminSlice.reducer;
