export const formatAmount = (amount) => {
   
    if (!amount || isNaN(amount)) return '₹0.00';
  
   
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  export const formatDate = (date) => {
   
    const validDate = typeof date === 'string' ? new Date(date) : date;
    
  
    if (!(validDate instanceof Date) || isNaN(validDate)) {
      console.error("Invalid date:", date);
      return null;
    }
  
    const year = validDate.getFullYear();
    const month = String(validDate.getMonth() + 1).padStart(2, '0');
    const day = String(validDate.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };
  