import React, { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { formatAmount, formatDate } from '../utils/helper';

const SortableHeader = ({ children }) => (
  <div className="flex items-center gap-2 cursor-pointer hover:text-gray-700">
    {children}
  </div>
);

const CreditLogsTable = ({ 
  data, 
  loading, 
  filters = { page: 1, limit: 10 }, 
  userId 
}) => {
  const navigate = useNavigate();
  const startIndex = (filters.page - 1) * filters.limit;

  const constructUrl = (id, type) => {
    const baseUrl = `/creditLogs/creditReport?id=${id}`;
    const queryParams = [];

    if (type === 'equifax') {
      queryParams.push('isEquifax=true');
    }
  
    if (type === 'cibil_signzy') {
      queryParams.push('isCibil=true');
      queryParams.push(`userInfo=${userId}`);
    }
    console.log(`${baseUrl}&${queryParams.join('&')}`)
    return queryParams.length ? `${baseUrl}&${queryParams.join('&')}` : baseUrl;
  };

  return (
    <div className="rounded-lg border border-gray-200 overflow-scroll">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {[
              'S.No', 'ID', 'Type', 'Score', 'Accounts Delinquent', 
              'Total Sanction Amount', 'Total Balance Amount', 
              'Single Highest Credit', 'Total Monthly Payment Amount', 
              'Created At', 'Action'
            ].map((header) => (
              <th 
                key={header} 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <SortableHeader>{header}</SortableHeader>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {loading ? (
            <tr>
              <td colSpan={11} className="px-6 py-10 text-center">
                <div className="flex justify-center">
                  <div className="w-6 h-6 border-2 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
                </div>
              </td>
            </tr>
          ) : data?.length === 0 ? (
            <tr>
              <td colSpan={11} className="px-6 py-10 text-center text-gray-500">
                No credit logs found
              </td>
            </tr>
          ) : (
            data?.map((item, index) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {startIndex + index + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                <Link className="underline"  to={constructUrl(item.id, item.type)}>
                {item.id}</Link>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.type}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.score}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.accountsDeliquent}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatAmount(item.totalSanctionAmount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatAmount(item.totalBalanceAmount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatAmount(item.singleHighestCredit)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatAmount(item.totalMonthlyPaymentAmount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <Moment format="DD MMM YYYY hh:mm:ss A">{item.createdAt}</Moment>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <Link
                    to={constructUrl(item.id, item.type)}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default memo(CreditLogsTable);