import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { showErrorToast, showSuccessToast } from '../../utils/toast';

const initialState = {
  featureConfigs: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10,
    hasNextPage: false,
    hasPrevPage: false,
  },
  loading: false,
  error: null,
  filters: {
    search: '',
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    moduleName: null,
    status: null,
  },
};

// Fetch feature configurations from the server
export const fetchFeatureConfigs = createAsyncThunk(
  'featureConfigs/fetchFeatureConfigs',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { filters } = getState().featureConfigs;
      const queryParams = new URLSearchParams({
        page: filters.page.toString(),
        limit: filters.limit.toString(),
        search: filters.search,
        sortBy: filters.sortBy,
        sortOrder: filters.sortOrder,
      });

      // Add optional filters
      if (filters.moduleName) {
        queryParams.append('moduleName', filters.moduleName);
      }
      if (filters.status !== null) {
        queryParams.append('status', filters.status.toString());
      }

      const response = await axiosInstance.get(`/feature-configs/get?${queryParams}`);
      return response.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to fetch feature configurations';
      showErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// Update feature configuration status
export const updateFeatureConfigStatus = createAsyncThunk(
  'featureConfigs/updateStatus',
  async ({ key, status }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/feature-configs/${key}/toggle`);
      
      // Show success toast
      showSuccessToast(`Feature ${key} ${status ? 'enabled' : 'disabled'} successfully`);
      
      // Refresh the list to ensure we have the latest data
      dispatch(fetchFeatureConfigs());
      
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to update feature configuration status';
      showErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const featureConfigsSlice = createSlice({
  name: 'featureConfigs',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
      if (action.payload.search || action.payload.moduleName) {
        state.filters.page = 1;
      }
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatureConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFeatureConfigs.fulfilled, (state, action) => {
        state.loading = false;
        state.featureConfigs = action.payload.featureConfigs || [];
        state.pagination = {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          itemsPerPage: action.payload.pageSize,
          hasNextPage: action.payload.totalPages !== action.payload.currentPage,
          hasPrevPage: action.payload.currentPage !== 1,
        };
      })
      .addCase(fetchFeatureConfigs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.featureConfigs = [];
      })
      .addCase(updateFeatureConfigStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFeatureConfigStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateFeatureConfigStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilter, resetFilters } = featureConfigsSlice.actions;
export default featureConfigsSlice.reducer;