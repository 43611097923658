import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../components/LoadingSpinner";
import axiosInstance from "../utils/axiosInstance";
const ToggleSwitch = ({ 
  checked, 
  onChange, 
  label, 
  enabledText = "Enabled", 
  disabledText = "Disabled" 
}) => {
  return (
    <div className="flex items-center space-x-3">
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            className="sr-only"
            checked={checked}
            onChange={onChange}
          />
          <div className={`
            w-12 h-6 
            ${checked ? 'bg-blue-500' : 'bg-gray-300'}
            rounded-full 
            shadow-inner 
            relative
            transition-colors 
            duration-300
          `}>
            <div className={`
              absolute 
              top-1/2 
              -translate-y-1/2
              w-5 h-5 
              bg-white 
              rounded-full 
              shadow 
              transform 
              transition-transform 
              duration-300
              ${checked ? 'translate-x-6' : 'translate-x-1'}
            `}></div>
          </div>
        </div>
        <span className="ml-3 text-sm text-gray-700">
          {checked ? enabledText : disabledText}
        </span>
      </label>
    </div>
  );
};
const InputField = ({ 
  label, 
  name, 
  type = "text", 
  value, 
  onChange, 
  error, 
  disabled = false, 
  placeholder,
  className = "" 
}) => {
  return (
    <div className="pb-4">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
        {error && <span className="text-red-500 ml-1">*</span>}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        className={`
          w-full 
          px-3 
          py-2 
          border 
          ${error ? 'border-red-500' : 'border-gray-300'} 
          rounded-md 
          shadow-sm 
          focus:outline-none 
          focus:ring-2 
          ${error ? 'focus:ring-red-500' : 'focus:ring-blue-500'}
          focus:border-transparent
          transition-all
          duration-200
          ${disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}
          ${className}
        `}
      />
      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}
    </div>
  );
};

const EditSettings = () => {
  const { settingsId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [optionData, setOptionData] = useState([]);
  const [formData, setFormData] = useState({
    key: "",
    value: "",
    valueType: 0,
    description: "",
    isPublic: false,
    featureType: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (settingsId) {
      getSettings();
    }
  }, [settingsId]);

  const getSettings = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/config/settings/${settingsId}`);
      const settingsDetails = response.data.data;

      if (settingsDetails) {
        setFormData({
          key: settingsDetails.key,
          value: settingsDetails.value,
          valueType: settingsDetails.valueType,
          description: settingsDetails.description,
          isPublic: settingsDetails.isPublic,
          featureType: settingsDetails.featureType,
        });

        if (settingsDetails.options) {
          const optionsArray = settingsDetails.options.split(',');
          setOptionData(optionsArray);
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error fetching settings");
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Key validation
    if (!formData.key) {
      newErrors.key = "Setting key is required";
    } else if (formData.key.length < 3) {
      newErrors.key = "Setting key must be at least 3 characters long";
    }

    // Value validation
    if (!formData.value) {
      newErrors.value = "Setting value is required";
    }

    // Description validation
    if (!formData.description) {
      newErrors.description = "Description is required";
    } else if (formData.description.length > 500) {
      newErrors.description = "Description can't be longer than 500 characters";
    }

    // Feature type validation
    if (!formData.featureType) {
      newErrors.featureType = "Feature type is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
     const form=formData
    delete form.key
      axiosInstance
        .put(`/config/settings/${settingsId}`, form)
        .then((response) => {
          toast.success("Settings updated successfully!");
          navigate(-1);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Error updating settings");
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (field) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const renderValueInput = () => {
    switch (formData.valueType) {
      case 1: // Text input
        return (
          <InputField
            label="Setting Value"
            name="value"
            value={formData.value}
            onChange={handleChange}
            error={errors.value}
            placeholder="Enter Setting Value"
          />
        );
      case 3: // Boolean toggle
        return (
          <div className="pb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Setting Value
          </label>
          <ToggleSwitch
            checked={formData.value === "true"}
            onChange={() =>
              setFormData((prevState) => ({
                ...prevState,
                value: prevState.value !== "true" ? "true" : "false",
              }))
            }
          />
        </div>
        );
      default: // Select input
        return (
          <div className="pb-4">
            <label htmlFor="value" className="block text-sm font-medium text-gray-700 mb-1">
              Value
              {errors.value && <span className="text-red-500 ml-1">*</span>}
            </label>
            <select
              id="value"
              name="value"
              value={formData.value}
              onChange={handleChange}
              className={`
                w-full 
                px-3 
                py-2 
                border 
                ${errors.value ? 'border-red-500' : 'border-gray-300'}
                rounded-md 
                shadow-sm 
                focus:outline-none 
                focus:ring-2 
                ${errors.value ? 'focus:ring-red-500' : 'focus:ring-blue-500'}
                focus:border-transparent
                transition-all
                duration-200
                bg-white
              `}
            >
              <option value="" disabled>
                Select Value
              </option>
              {optionData.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.value && (
              <p className="mt-1 text-sm text-red-500">{errors.value}</p>
            )}
          </div>
        );
    }
  };

  return (
    <div className=" mx-auto p-6 bg-white shadow-md rounded-lg">
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Edit Settings</h1>
      </div>
      {loading && <LoadingSpinner />}

      {!loading && (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid md:grid-cols-2 gap-6">
            <InputField
              label="Setting Key"
              name="key"
              value={formData.key}
              disabled={true}
              error={errors.key}
              placeholder="Enter Setting Key"
            />

            {renderValueInput()}

            <InputField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              error={errors.description}
              placeholder="Enter Description"
            />

<div className="flex items-center space-x-4">
        <ToggleSwitch
          checked={formData.isPublic}
          onChange={() => handleCheckboxChange("isPublic")}
          enabledText="Public"
          disabledText="Private"
        />
      </div>

            <div className="flex items-center">
              <span className="text-sm font-medium text-gray-700 mr-2">Feature Type:</span>
              <span className="text-gray-900">{formData.featureType}</span>
            </div>
          </div>

          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="
                px-6 
                py-2 
                bg-blue-500 
                text-white 
                rounded-md 
                hover:bg-blue-600 
                focus:outline-none 
                focus:ring-2 
                focus:ring-blue-500 
                focus:ring-opacity-50 
                transition-colors 
                duration-300
              "
            >
              Update Settings
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditSettings;