import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { showErrorToast, showSuccessToast } from '../../utils/toast';
import { formatDate } from '../../utils/helper';

const initialState = {
  roles: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10,
    hasNextPage: false,
    hasPrevPage: false
  },
  loading: false,
  creating: false,
  error: null,
  filters: {
    search: '',
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    startDate: null,
    endDate: null,
  },
};

export const createRole = createAsyncThunk(
  'admin/createRole',
  async (roleData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/admin/role/create', roleData);
      showSuccessToast('Role created successfully');
      // Refresh the roles list after creating
      dispatch(fetchRoles());
      return response.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to create role';
      showErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchRoles = createAsyncThunk(
  'admin/roles',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { filters } = getState().roles;
      
      const queryParams = new URLSearchParams({
        page: filters.page,
        limit: filters.limit,
        search: filters.search,
        sortBy: filters.sortBy,
        sortOrder: filters.sortOrder,
      });

      if (filters.startDate && filters.endDate) {
        queryParams.set('startDate', formatDate(filters.startDate));
        queryParams.set('endDate', formatDate(filters.endDate));
      }

      const response = await axiosInstance.get(`/admin/role?${queryParams}`);
      return response.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to fetch roles';
      showErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const rolesSlice = createSlice({
  name: 'adminRoles',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
      if (action.payload.search) {
        state.filters.page = 1;
      }
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch roles reducers
      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload || [];
        state.pagination = {
          currentPage: 1,
          totalPages: 1,
          totalItems: action.payload.length,
          itemsPerPage: 10,
          hasNextPage: false,
          hasPrevPage: false
        };
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create role reducers
      .addCase(createRole.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.creating = false;
        // Don't need to update state here as fetchRoles will be called after successful creation
      })
      .addCase(createRole.rejected, (state, action) => {
        state.creating = false;
        state.error = action.payload;
      });
  },
});

export const { setFilter, resetFilters } = rolesSlice.actions;
export default rolesSlice.reducer;