import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchFeatureConfigs, setFilter, resetFilters, updateFeatureConfigStatus } from '../store/slices/featureConfigsSlice';
import { Search } from 'lucide-react';
import debounce from 'lodash/debounce';
import Pagination from '../components/Paginate';
import ToggleSwitch from '../components/ToggleSwitch';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FeatureConfigDetailsModal from '../components/Modal/FeatureConfigDetailsModal';

const FeatureConfigs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { featureConfigs, pagination, loading, filters } = useSelector((state) => state.featureConfigs);
  const [localSearch, setLocalSearch] = useState(filters.search);
  const [isInitialized, setIsInitialized] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        dispatch(setFilter({ search: value, page: 1 }));
      }, 500),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    const restoreFilters = () => {
      const initialFilters = {
        page: parseInt(searchParams.get('page')) || 1,
        limit: parseInt(searchParams.get('limit')) || 10,
        search: searchParams.get('search') || '',
        sortBy: searchParams.get('sortBy') || 'createdAt',
        sortOrder: searchParams.get('sortOrder') || 'desc',
        moduleName: searchParams.get('moduleName'),
        status: searchParams.get('status'),
      };

      dispatch(setFilter(initialFilters));
      setLocalSearch(initialFilters.search);
      setIsInitialized(true);
    };

    restoreFilters();
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (!isInitialized) return;

    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        params.set(key, value.toString());
      }
    });

    setSearchParams(params, { replace: true });
    dispatch(fetchFeatureConfigs());
  }, [filters, isInitialized, setSearchParams, dispatch]);

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setLocalSearch(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const handleClearFilters = useCallback(() => {
    dispatch(resetFilters());
    setLocalSearch('');
    debouncedSearch.cancel();
    setSearchParams({}, { replace: true });
  }, [dispatch, debouncedSearch, setSearchParams]);

  const handleSort = (column) => {
    const newSortOrder = filters.sortBy === column && filters.sortOrder === 'asc' ? 'desc' : 'asc';
    dispatch(setFilter({ sortBy: column, sortOrder: newSortOrder, page: 1 }));
  };

  const handleStatusToggle = (configKey, currentStatus) => {
    confirmAlert({
      title: 'Confirm Status Change',
      message: `Are you sure you want to ${currentStatus ? 'disable' : 'enable'} this feature?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(updateFeatureConfigStatus({ key: configKey, status: !currentStatus }));
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">Feature Configurations</h1>
        <button
          onClick={handleClearFilters}
          className="px-4 py-2 border border-gray-200 rounded-md text-sm font-medium hover:bg-gray-50 transition-colors"
        >
          Clear Filters
        </button>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
          <input
            type="text"
            placeholder="Search configurations..."
            value={localSearch}
            onChange={handleSearchChange}
            className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      <div className="rounded-lg border border-gray-200 overflow-hidden">
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        ) : (
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th onClick={() => handleSort('key')} className="px-6 py-3 text-left cursor-pointer">
                  Key
                </th>
                <th className="px-6 py-3 text-left">Module</th>
                <th className="px-6 py-3 text-left">Value</th>

                <th className="px-6 py-3 text-left">Status</th>
                <th className="px-6 py-3 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {featureConfigs.map((config) => (
                <tr key={config.id}>
                  <td className="px-6 py-4">{config.key}</td>
                  <td className="px-6 py-4">{config.moduleName}</td>
                  <td className="px-6 py-4">
                    <FeatureConfigDetailsModal 
                      config={config}
                      trigger={
                        <button className="px-4 py-2 border border-blue-600 rounded hover:bg-blue-50">
                          Details
                        </button>
                      }
                    />
                  </td>
                  <td className="px-6 py-4">
                    <ToggleSwitch
                      checked={config.isActive}
                      onChange={() => handleStatusToggle(config.key, config.isActive)}
                    />
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => navigate(`/featureConfigs/${config.key}`)}
                      className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {!loading && featureConfigs.length > 0 && (
        <Pagination
          currentPage={filters.page}
          totalItems={pagination.totalItems}
          pageSize={filters.limit}
          onPageChange={(page) => dispatch(setFilter({ page }))}
          onPageSizeChange={(limit) => dispatch(setFilter({ limit, page: 1 }))}
        />
      )}
    </div>
  );
};

export default FeatureConfigs;