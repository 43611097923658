import { Routes, Route, Navigate } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { AuthProvider } from './context/AuthContext';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import LoadingSpinner from './components/LoadingSpinner';
import * as ServiceWorkerRegistration from './ServiceWorkerRegistration';
import EditSettings from './pages/EditSettings';
import FeatureConfigs from './pages/FeatureConfigs';
import EditfeatureConfig from './pages/EditFeatueConfig';
import CreditLogs from './pages/CreditLogs';
import CreditInfo from './pages/CreditReport';
import CreditEnquiry from './pages/CreditEnquiry';
import ExperianDetails from './pages/ExperianDetails';
import ApiLogs from './pages/ApiLogs';

const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Customers = lazy(() => import('./pages/Customers'));
const Loan = lazy(() => import('./pages/Loan'));
const LoanApplication = lazy(() => import('./pages/LoanApplication'));
const SettingsList = lazy(() => import('./pages/Settings'));
const Roles = lazy(() => import('./pages/Roles'));
const CustomerDetails = lazy(() => import('./pages/CustomerDetail'));
const AdminListing = lazy(() => import('./pages/Admin'));
const LoanDetails = lazy(() => import('./pages/LoanInfo'));

const PermissionManagement = lazy(() => import('./pages/PermissionManagement'));




const DynamicTitleLayout = lazy(() => import('./components/DynamicTitlelayout'));

// Loading component


const App = () => {

  useEffect(() => {
    // Add version meta tag
    const metaTag = document.createElement('meta');
    metaTag.name = 'app-version';
    metaTag.content = process.env.REACT_APP_VERSION || Date.now().toString();
    document.head.appendChild(metaTag);

    // Register service worker
    ServiceWorkerRegistration.register({
      onUpdate: (registration) => {
        const confirmReload = window.confirm(
          'A new version of the app is available. Reload now?'
        );
        if (confirmReload) {
          window.location.reload();
        }
      }
    });
  }, []);
  
  return (
    <Provider store={store}>
    <AuthProvider>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          <Route
            element={<DynamicTitleLayout />}
          >
            <Route 
              path="/dashboard" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Dashboard />
                </Suspense>
              } 
            />
            <Route 
              path="/customers" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Customers />
                </Suspense>
              } 
              
            />
             <Route 
              path="/admin" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminListing />
                </Suspense>
              } 
              
            />
             <Route 
              path="/customers/view/:id" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <CustomerDetails />
                </Suspense>
              } 
            />
               <Route 
              path="/creditLogs" 
              element={
               
                  <CreditLogs />
              } 
            />
                  <Route 
              path="/creditLogs/creditReport" 
              element={
               
                  <CreditInfo />
              } 
            />
                <Route 
              path="/creditLogs/CreditEnquiry/:id" 
              element={
               
                  <CreditEnquiry />
              } 
            />
               <Route 
              path="/creditLogs/creditReport/:id" 
              element={
               
                  <ExperianDetails />
              } 
            />

<Route 
              path="/apiLogs" 
              element={
               
                  <ApiLogs />
              } 
            />
              <Route 
              path="/roles" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Roles />
                </Suspense>
              } 
            />
               <Route 
              path="/roles/:roleId" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <PermissionManagement />
                </Suspense>
              } 
            />
            <Route 
              path="/loans" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Loan />
                </Suspense>
              } 
            />
                 <Route 
              path="/loans/:id" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <LoanDetails />
                </Suspense>
              } 
            />
            <Route 
              path="/loan-applications" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <LoanApplication />
                </Suspense>
              } 
            />
                      <Route path="/settings" element={<SettingsList/>} />
                      <Route path="/featureConfigs" element={<FeatureConfigs/>} />
                      <Route path="/featureConfigs/:configId" element={<EditfeatureConfig/>} />
                      
                      <Route 
              path="/settings/:settingsId" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <EditSettings />
                </Suspense>
              } 
            />
          </Route>
      


          {/* Catch-all route for redirects */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Suspense>
    </AuthProvider>
    </Provider>
  );
};

export default App;