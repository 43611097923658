import axios from 'axios';
export const baseUrl ="https://admin-backend-payday.netsolutionindia.com/admin/api/v1";

const axiosInstance = axios.create({
  baseURL: baseUrl, 
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
  
    const token = localStorage.getItem('token'); 
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("first")
    return Promise.reject(error);
  }
);

// Response interceptor for handling responses and errors globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("first")
    console.error("Interceptor caught an error:", error);

    if (error.response) {
      console.log("Error Response Status:", error.response.status);
      
      // Handle 401 Unauthorized
      if (error.response.status === 401) {
        console.log("401 Unauthorized - Redirecting to login.");
        localStorage.clear()
        window.location.href = '/login';
        return; // Optional: Stop further error propagation
      }
    } else {
      // Handle cases where no response is received
      console.error("No response received:", error.message);
    }

    return Promise.reject(error);
  }

);

export default axiosInstance;
