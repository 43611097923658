import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Search } from "lucide-react";
import debounce from "lodash/debounce";
import Pagination from "../components/Paginate";
import {
  fetchApiLogs,
  setFilter,
  resetFilters,
} from "../store/slices/apiLogsSlice";
import ApiLogsTable from "../Table/ApiLogsTable";
import DateRangePicker from '../components/DateRangePicker';

const ApiLogs = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { apiLogs, pagination, loading, filters } = useSelector(
    (state) => state.apiLogs
  );
  const [localSearch, setLocalSearch] = useState(() => searchParams.get("search") || "");
  const [isInitialized, setIsInitialized] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        dispatch(setFilter({ search: value, page: 1 }));
      }, 500),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    const restoreFilters = () => {
      const initialFilters = {
        page: parseInt(searchParams.get("page")) || 1,
        limit: parseInt(searchParams.get("limit")) || 10,
        search: searchParams.get("search") || "",
        searchType: searchParams.get("searchType") || "",
        sortBy: searchParams.get("sortBy") || "createdAt",
        sortOrder: searchParams.get("sortOrder") || "desc",
        startDate: searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : null,
        endDate: searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : null
      };

      const hasUrlParams = Array.from(searchParams.entries()).length > 0;
      if (hasUrlParams) {
        dispatch(setFilter(initialFilters));
        setLocalSearch(initialFilters.search);
      } else {
        const defaultFilters = { 
          page: 1, 
          limit: 10, 
          search: "",
          searchType: "",
          sortBy: "createdAt",
          sortOrder: "desc",
          startDate: null,
          endDate: null
        };
        dispatch(setFilter(defaultFilters));
        setSearchParams(defaultFilters, { replace: true });
      }
      setIsInitialized(true);
    };

    restoreFilters();
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    const updateUrlAndFetch = () => {
      const params = new URLSearchParams();
      
      const isIncompleteDateRange = 
        (filters.startDate && !filters.endDate) || 
        (!filters.startDate && filters.endDate);

      if (isIncompleteDateRange) {
        return;
      }

      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          if (key === 'page' && value === 1) return;
          if (key === 'limit' && value === 10) return;
          if (key === 'search' && value === '') return;
          if (key === 'searchType' && value === '') return;
          if (key === 'sortBy' && value === 'createdAt') return;
          if (key === 'sortOrder' && value === 'desc') return;
          params.set(key, value instanceof Date ? value.toISOString().split('T')[0] : value);
        }
      });

      const currentParams = new URLSearchParams(searchParams);
   
      const paramsChanged = Array.from(params.entries()).some(
        ([key, value]) => currentParams.get(key) !== value
      ) || 
      (currentParams.has('startDate') && !filters.startDate) ||
      (currentParams.has('endDate') && !filters.endDate);

      if (paramsChanged) {
        setSearchParams(params, { replace: true });
      }

      dispatch(fetchApiLogs());
    };

    updateUrlAndFetch();
  }, [filters, isInitialized]);

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setLocalSearch(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const handleSearchTypeChange = (e) => {
    dispatch(setFilter({ searchType: e.target.value, page: 1 }));
  };

  const handleDateChange = (dates) => {
    if (!dates.startDate && !dates.endDate) {
      dispatch(setFilter({ startDate: null, endDate: null, page: 1 }));
    } else {
      dispatch(setFilter({ ...dates, page: 1 }));
    }
  };

  const handleClearFilters = useCallback(() => {
    dispatch(resetFilters());
    setLocalSearch("");
    debouncedSearch.cancel();
    setSearchParams({}, { replace: true });
  }, [dispatch, debouncedSearch, setSearchParams]);

  return (
    <div className="p-6 space-y-6 ">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">API Logs</h1>
        <button
          onClick={handleClearFilters}
          className="px-4 py-2 border border-gray-200 rounded-md text-sm font-medium hover:bg-gray-50 transition-colors"
        >
          Clear Filters
        </button>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
          <input
            type="text"
            placeholder="Search API logs..."
            value={localSearch}
            onChange={handleSearchChange}
            className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <div className="w-full sm:w-[200px]">
          <select
            value={filters.searchType}
            onChange={handleSearchTypeChange}
            className="w-full px-4 py-2 border border-gray-200 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">All Types</option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </select>
        </div>
        <div className="w-full sm:w-[300px]">
          <DateRangePicker
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={handleDateChange}
          />
        </div>
      </div>

      <div className="rounded-lg border border-gray-200 overflow-hidden">
        <ApiLogsTable
          logs={apiLogs}
          loading={loading}
          filters={filters}
        />
      </div>

      {!loading && apiLogs?.length > 0 && (
        <Pagination
          currentPage={filters.page}
          totalItems={pagination.totalItems}
          pageSize={filters.limit}
          onPageChange={(page) => dispatch(setFilter({ page }))}
          onPageSizeChange={(limit) => dispatch(setFilter({ limit, page: 1 }))}
        />
      )}
    </div>
  );
};

export default ApiLogs;