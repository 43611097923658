import React, { useState } from 'react';
import { X } from 'lucide-react';

const FeatureConfigDetailsModal = ({ config, trigger }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const valueList = config.value.map((item, index) => (
    <div className="space-y-3">
 
      <div 
        key={index} 
        className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-300"
      >
        {Object.entries(item).map(([key, value]) => (
          <div key={key} className="mb-2 last:mb-0">
            <span className="font-semibold text-gray-700 mr-2">
              {key.charAt(0).toUpperCase() + key.slice(1)}:
            </span>
            <span className="text-gray-600">
              {/* Handle different types of values */}
              {typeof value === 'object' 
                ? JSON.stringify(value) 
                : String(value)}
            </span>
          </div>
        ))}
      </div>

  </div>
  ));

  return (
    <>
      <div onClick={handleOpen}>
        {trigger}
      </div>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          {/* Backdrop */}
          <div 
            className="fixed inset-0 bg-black opacity-50" 
            onClick={handleClose}
          ></div>
          
          {/* Modal Container */}
          <div className="relative w-full max-w-2xl mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              {/* Header */}
              <div className="flex items-center justify-between p-5 border-b border-solid border-gray-200 rounded-t">
                <h3 className="text-2xl font-semibold text-gray-900">
                  Feature Configuration - {config.key}
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-gray-600 float-right text-3xl leading-none font-semibold outline-none focus:outline-none hover:text-gray-900"
                  onClick={handleClose}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              {/* Body */}
              <div className="relative p-6 flex-auto space-y-4">
                <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                  <div className="mb-3">
                    <span className="font-semibold text-gray-700">Description: </span>
                    <span className="text-gray-600">{config.description}</span>
                  </div>
                  <div>
                    <span className="font-semibold text-gray-700">Status: </span>
                    <span 
                      className={`px-2 py-1 rounded-full text-sm ${
                        config.isActive 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}
                    >
                      {config.isActive ? 'Enabled' : 'Disabled'}
                    </span>
                  </div>
                </div>
                
                <div>
                  <h3 className="text-lg font-semibold text-gray-800 mb-3">Configuration Values:</h3>
                  <div className="space-y-2">
                    {valueList}
                  </div>
                </div>
              </div>
              
              {/* Footer */}
              <div className="flex items-center justify-end p-6 border-t border-solid border-gray-200 rounded-b">
                <button
                  className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeatureConfigDetailsModal;