import React, { useState, useEffect } from 'react';
import { FaCog, FaMoneyBill, FaUsers, FaUserShield, FaClipboardList, FaFileAlt } from 'react-icons/fa';
import { GiPayMoney } from 'react-icons/gi';
import { IoMdHome, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { IoSettings, IoNotifications } from 'react-icons/io5';
import { MdVerifiedUser } from 'react-icons/md';
import { BsFileEarmarkText } from 'react-icons/bs';
import { useNavigate, useLocation } from 'react-router-dom';

const MenuItem = ({ item, show, isExpanded, toggleExpand, navigate, activeRoute }) => {
  const hasSubItems = item.subItems && item.subItems.length > 0;
  const isActive = activeRoute === item.path;
  const isParentActive = hasSubItems && item.subItems.some(subItem => activeRoute === subItem.path);

  return (
    <div className="text-blue-100">
      <div
        className={`
          flex items-center p-4 cursor-pointer transition-all duration-200
          ${hasSubItems ? 'hover:bg-blue-700' : ''}
          ${isActive  ? 'bg-blue-600 border-l-4 border-blue-100' : 'hover:bg-blue-600'}
        `}
        onClick={() => (hasSubItems ? toggleExpand(item.title) : (item.path && navigate(item.path)))}
      >
        <item.icon 
          className={`flex-shrink-0 ${isActive ? 'text-blue-300' : 'text-blue-200'}`} 
          size={20} 
        />
        {show && (
          <>
            <span className={`ml-3 flex-grow ${isActive  ? 'text-white font-semibold' : ''}`}>
              {item.title}
            </span>
            {hasSubItems && (
              <div className="ml-4">
                {isExpanded ? (
                  <IoIosArrowDown size={16} className="text-blue-200" />
                ) : (
                  <IoIosArrowForward size={16} className="text-blue-200" />
                )}
              </div>
            )}
          </>
        )}
      </div>

      {hasSubItems && isExpanded && show && (
        <div className="ml-8 border-l border-blue-600">
          {item.subItems.map((subItem, index) => (
            <div
              key={index}
              className={`
                flex items-center p-3 cursor-pointer transition-all duration-200
                ${activeRoute === subItem.path ? 'bg-blue-700 border-l-4 border-blue-400' : 'hover:bg-blue-600'}
              `}
              onClick={() => subItem.path && navigate(subItem.path)}
            >
              <subItem.icon 
                className={`flex-shrink-0 ${activeRoute === subItem.path ? 'text-blue-400' : 'text-blue-200'}`} 
                size={16} 
              />
              <span className={`ml-3 ${activeRoute === subItem.path ? 'text-white font-semibold' : ''}`}>
                {subItem.title}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


const Sidebar = ({ show = true, setSidebar, mobileSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedMenus, setExpandedMenus] = useState(new Set());

  const toggleExpand = (menuTitle) => {
    setExpandedMenus(prev => {
      const newSet = new Set(prev);
      if (newSet.has(menuTitle)) {
        newSet.delete(menuTitle);
      } else {
        newSet.add(menuTitle);
      }
      return newSet;
    });
  };


  const menuItems = [
    { 
      title: 'Dashboard', 
      path: '/dashboard', 
      icon: IoMdHome 
    },
    {
      title: 'Admin',
      icon: FaUserShield,
      subItems: [
        { 
          title: 'Admin', 
          path: '/admin', 
          icon: FaUserShield 
        },
        { 
          title: 'Roles', 
          path: '/roles', 
          icon: FaUserShield 
        }
      ]
    },
    { 
      title: 'Customers', 
      path: '/customers', 
      icon: FaUsers
    },
    {
      title: 'Loans',
      icon: GiPayMoney,
      subItems: [
        { 
          title: 'All Loans', 
          path: '/loans', 
          icon: GiPayMoney 
        }
      ]
    },
    {
      title: 'Loan Applications',
      icon: FaMoneyBill,
      subItems: [
        { 
          title: 'Applications', 
          path: '/loan-applications', 
          icon: FaMoneyBill 
        }
      ]
    },
    { 
      title: 'Verification', 
      path: '/verification', 
      icon: MdVerifiedUser 
    },
  
    {
      title: 'Config',
      icon: FaCog,
      subItems: [
        { 
          title: 'Setting', 
          path: '/settings', 
          icon: IoSettings 
        },
        { 
          title: 'Feature Configuration', 
          path: '/featureConfigs', 
          icon: FaCog 
        },
        { 
          title: 'Secret', 
          path: '/secret', 
          icon: FaCog 
        }
      ]
    },
    {
      title: 'Logs',
      icon: FaClipboardList,
      subItems: [
        { 
          title: 'API Logs', 
          path: '/apiLogs', 
          icon: FaFileAlt 
        },
    
      ]
    }
  ];

  useEffect(() => {
    const activeMenuItem = menuItems.find(item => 
      item.path === location.pathname || 
      (item.subItems && item.subItems.some(subItem => subItem.path === location.pathname))
    );

    if (activeMenuItem && activeMenuItem.subItems) {
      setExpandedMenus(prev => new Set(prev).add(activeMenuItem.title));
    }
  }, [location.pathname]);

  return (
    <div className={`
      bg-blue-800 min-h-screen max-h-screen 
      ${mobileSidebar ? 'w-64' : show ? 'w-64' : 'w-20'} 
      transition-all duration-300 overflow-y-auto
    `}>
      <div className="p-4">
        <h1 className={`text-xl font-bold text-white ${!show && 'hidden'}`}>
          PAYDAY
        </h1>
      </div>
      <div className="mt-4">
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            item={item}
            show={show}
            isExpanded={expandedMenus.has(item.title)}
            toggleExpand={toggleExpand}
            navigate={navigate}
            activeRoute={location.pathname}
          />
        ))}
      </div>
    </div>
  );
};


export default Sidebar;