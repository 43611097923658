import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { showErrorToast } from '../../utils/toast';
import { formatDate } from '../../utils/helper';


const initialState = {
  loanApplications: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10,
    hasNextPage: false,
    hasPrevPage: false
  },
  loading: false,
  error: null,
  filters: {
    search: '',
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    startDate: null,
    endDate: null,
  },
};

export const fetchLoanApplication = createAsyncThunk(
  'loans/loan-applications',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { filters } = getState().loanApplications;
      console.log(filters)
      const queryParams = new URLSearchParams({
        page: filters.page,
        limit: filters.limit,
        search: filters.search,
      });
      
      // if (filters.type) {
      //   queryParams.set('type', filters.type);
      // }
      if (filters.startDate && filters.endDate) {
        queryParams.set('startDate', formatDate(filters.startDate))
        queryParams.set('endDate', formatDate(filters.endDate))
      }
      
     

      const response = await axiosInstance.get(`/loan/loan-applications?${queryParams}`);
      console.log(response.data.data); 
      return response.data.data; 
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to fetch loans applications';
      showErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const loanApplicationsSlice = createSlice({
  name: 'loanApplications',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
      if ( action.payload.search || action.payload.status) {
        state.filters.page = 1;
      }
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLoanApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.loanApplications = action.payload.loanApplications || []; 
        state.pagination = action.payload.pagination || initialState.pagination; 
      })
      .addCase(fetchLoanApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilter, resetFilters } = loanApplicationsSlice.actions;
export default loanApplicationsSlice.reducer;
